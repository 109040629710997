// @flow
/* global $Values */
import React, { createContext, useContext } from 'react';
import { withTheme } from 'styled-components';
import defaultTheme from '../../../themes/default';
import { FlexboxGridJustify } from './FlexboxGrid.constants';
import { FlexboxGridStyled, FlexboxGridInnerStyled, DebugFlexboxGridInnerStyled, DebugCol } from './FlexboxGrid.style';

type FlexboxGridProps = {
    /**
     * Any valid value for CSS `justify-content`
     * https://developer.mozilla.org/en-US/docs/Web/CSS/justify-content
     */
    justify: $Values<typeof FlexboxGridJustify>,
    /**
     * Show the debug styling. Useful for dev. Defaults to `false`
     */
    debug?: boolean,
    /**
     * `className` can be provided if extending
     */
    className?: string,
    /**
     * The total number of columns this grid should span,
     * This is used by all of the columns to work out their widths
     */
    totalCols: number,
    /**
     * Theme object from Styled Components
     */
    theme: Object,
    /**
     * Grid config object, must match the format in the theme. Like:
     *
     *```
     *{
     *    base: {
     *        verticalSpacing: 24,
     *        horizontalSpacing: 24
     *    },
     *    mediumDesktop: {
     *        verticalSpacing: 48,
     *        horizontalSpacing: 48,
     *    },
     *}
     *```
     */
    config: Object,
};

const defaultContext = { totalCols: 12 };

Object.keys(defaultTheme.screenSize).forEach((breakpoint) => {
    const breakpointProp = `${breakpoint}TotalCols`;
    defaultContext[breakpointProp] = 12;
});
const FlexboxGridContext = createContext({ ...defaultContext });

const FlexboxGrid = ({ justify, theme, config, debug, className, children, totalCols, ...props }: FlexboxGridProps) => {
    const { config: inheritedConfig } = useContext(FlexboxGridContext);

    const gridConfig = config || inheritedConfig || theme.grid;
    const breakpointProps = {};

    let prevTotalCols = totalCols;
    const availableScreenSizes = [];
    Object.keys(defaultTheme.screenSize).forEach((breakpoint) => {
        availableScreenSizes.push([breakpoint, defaultTheme.screenSize[breakpoint]]);
    });
    availableScreenSizes.sort((a, b) => (a[1] > b[1] ? 1 : -1));

    availableScreenSizes.forEach((breakpoint) => {
        const breakpointProp = `${breakpoint[0]}TotalCols`;
        if (breakpointProp in props) {
            breakpointProps[breakpointProp] = props[breakpointProp];
            prevTotalCols = props[breakpointProp];
        }
        else {
            breakpointProps[breakpointProp] = prevTotalCols;
        }
    });

    return (
        <FlexboxGridStyled debug={debug} className={className}>
            <FlexboxGridContext.Provider value={{ totalCols, config: gridConfig, ...breakpointProps }}>
                {debug && (
                    <DebugFlexboxGridInnerStyled config={gridConfig}>
                        {Array(totalCols)
                            .fill()
                            .map((_, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <DebugCol key={i} totalCols={totalCols} config={gridConfig} />
                            ))}
                    </DebugFlexboxGridInnerStyled>
                )}

                <FlexboxGridInnerStyled config={gridConfig} justify={justify}>
                    {children}
                </FlexboxGridInnerStyled>
            </FlexboxGridContext.Provider>
        </FlexboxGridStyled>
    );
};

FlexboxGrid.defaultProps = {
    justify: FlexboxGridJustify.FLEX_START,
    totalCols: 12,
    debug: false,
};

FlexboxGrid.Justify = FlexboxGridJustify;

const ThemedFlexboxGrid = withTheme(FlexboxGrid);

export { ThemedFlexboxGrid as default, FlexboxGridContext };
