import colors from './colors';

const palette = {
    primaryRegular: colors.teal[500].hex,
    primaryDark: colors.teal[700].hex,
    primarySoft: colors.teal[400].hex,
    primarySoftest: colors.teal[100].hex,
    accent: colors.pink[500].hex,
    danger: colors.red[500].hex,
    text: colors.grey[700].hex,
    textLight: colors.grey[500].hex,
    textWhite: colors.white.hex,
    link: colors.teal[700].hex,
    linkDark: colors.grey[600].hex,
    icon: colors.grey[500].hex,
    iconDark: colors.grey[700].hex,
    borderLight: colors.grey[300].hex,
    border: colors.grey[400].hex,
    borderDark: colors.grey[500].hex,
    highlight: colors.grey[100].hex,
    // yellow
    yellow: colors.yellow[500].hex,
    lightYellow: colors.yellow[200].hex,
    // hover background color on white
    hoverGrey: colors.grey[300].hex,
    // Focus ring color
    focus: colors.blue[500].hex,
    // monochrome colors when a named variable doesn't quite fit
    white: colors.white.hex,
    black: colors.black.hex,
    grey: {
        700: colors.grey[700].hex,
        600: colors.grey[600].hex,
        500: colors.grey[500].hex,
        400: colors.grey[400].hex,
        300: colors.grey[300].hex,
        200: colors.grey[200].hex,
        100: colors.grey[100].hex,
    },
};

const headerFontStack = "henriette,Georgia,Times,'Times New Roman',serif";

const muliFont = 'Muli, sans-serif';

const theme = {
    palette,
    spacing: {
        // padding and margins
        0: '0',
        0.5: '4px',
        1: '8px',
        1.5: '12px',
        2: '16px',
        2.5: '20px',
        3: '24px',
        3.5: '28px',
        4: '32px',
        4.5: '36px',
        5: '40px',
        6: '48px',
        7: '56px',
        8: '64px',
        9: '72px',
        10: '80px',
        11: '88px',
        12: '96px',
        13: '104px',
        117: '936px',
    },
    sizing: {
        touch: '48px',
    },
    borderRadius: {
        regular: '5px',
        button: '4px',
        small: '3px',
    },
    headerFontStack,
    fontFamily: muliFont,
    fontSizes: {
        xsmall: '0.625rem',
        small: '0.75rem',
        medium: '1rem',
        large: '1.5rem',
        xlarge: '2rem',
        xxlarge: '3rem',
    },
    fontWeights: {
        normal: 400,
        semiBold: 600,
        bold: 700,
    },
    typography: {
        h1large: {
            'font-family': headerFontStack,
            /* font-size: 72px */
            /* line-height: 80px */
            'font-size': '4.5rem',
            'line-height': '1.111111',
            'font-weight': '500',
            color: `${palette.text}`,
        },
        h1: {
            'font-family': headerFontStack,
            /* font-size: 40px */
            /* line-height: 48px */
            'font-size': '2.5rem',
            'line-height': '1.2',
            'font-weight': '500',
            color: `${palette.text}`,
        },
        h1small: {
            'font-family': headerFontStack,
            /* font-size: 32px */
            /* line-height: 48px */
            'font-size': '2rem',
            'line-height': '1.5',
            'font-weight': '500',
            color: `${palette.text}`,
        },
        h2: {
            'font-family': headerFontStack,
            /* font-size: 24px */
            /* line-height: 32px */
            'font-size': '1.5rem',
            'line-height': '1.333333',
            'font-weight': '500',
            color: `${palette.text}`,
        },
        h2Variant: {
            'font-family': muliFont,
            /* font-size: 24px */
            /* line-height: 32px */
            'font-size': '1.5rem',
            'line-height': '1.333333',
            'font-weight': '600',
            color: `${palette.text}`,
        },
        h3: {
            'font-family': headerFontStack,
            /* font-size: 20px */
            /* line-height: 24px */
            'font-size': '1.25rem;',
            'line-height': '1.2',
            'font-weight': '500',
            color: `${palette.text}`,
        },
        h3Variant: {
            'font-family': muliFont,
            /* font-size: 20px */
            /* line-height: 24px */
            'font-size': '1.25rem;',
            'line-height': '1.2',
            'font-weight': '600',
            color: `${palette.text}`,
        },
        h4: {
            'font-family': headerFontStack,
            /* font-size: 17px */
            /* line-height: 24px */
            'font-size': '1.0625rem',
            'line-height': '1.4117647058',
            'font-weight': '500',
            color: `${palette.text}`,
        },
        p: {
            /* font-size: 16px */
            /* line-height: 24px */
            'font-size': '1rem',
            'line-height': '1.5',
            'font-weight': '400',
        },
        focalSmall: {
            /* font-size: 20px */
            /* line-height: 32px */
            'font-size': '1.25rem',
            'line-height': '1.333333',
            'font-weight': '400',
        },
        span: {
            /* font-size: 16px */
            /* line-height: 24px */
            'font-size': '1rem',
            'line-height': '1.5',
            'font-weight': '400',
        },
        label: {
            'font-size': '1rem',
            'line-height': '1.5',
            'font-weight': '600',
            color: `${palette.text}`,
        },
        blockquote: {
            /* font-size: 40px */
            /* line-height: 48px */
            'font-size': '2.5rem',
            'line-height': '1.2',
            'font-weight': '400',
        },
        smallText: {
            /* font-size: 14px */
            /* line-height: 24px */
            'font-size': '0.875rem',
            'line-height': '1.71428571429',
        },
        reallySmallText: {
            /* font-size: 14px */
            /* line-height: 20px */
            'font-size': '0.75rem',
            'line-height': '1.4285714286',
        },
        a: {
            color: 'blue',
        },
    },
    // https://material.io/guidelines/layout/responsive-ui.html#responsive-ui-breakpoints
    breakpoints: {
        xsmall: '480',
        small: '600',
        medium: '840',
        large: '960',
        xlarge: '1280',
        xxlarge: '1440',
    },
    zIndexLayers: [
        'imageShadow',
        'navigationButtons',
        'stickyFilterBar',
        'topnav',
        'searchFilters',
        'tooltip',
        'modal',
        'lightbox',
    ],
    screenSize: {
        mediumHandset: 375,
        largeHandset: 480,
        smallTablet: 600,
        mediumTablet: 720,
        largeTablet: 800,
        smallDesktop: 960,
        mediumDesktop: 1024,
        largeDesktop: 1280,
        wide: 1440,
        extraWide: 1920,
    },
    grid: {
        base: {
            verticalSpacing: 32,
            horizontalSpacing: 16,
        },
        mediumDesktop: {
            verticalSpacing: 48,
            horizontalSpacing: 32,
        },
    },
    opacityOverlay: {
        light: {
            hover: {
                strength: 0.06,
                color: palette.black,
            },
            active: {
                strength: 0.12,
                color: palette.black,
            },
        },
        dark: {
            hover: {
                strength: 0.12,
                color: palette.white,
            },
            active: {
                strength: 0.24,
                color: palette.white,
            },
            hoverText: {
                strength: 0.48,
                color: palette.white,
            },
        },
    },
    disabledOpacity: 0.54, // adds an opacity to indicate a component is disabled
    activeOpacity: 0.78, // blend value for button hover and active states
    overlayOpacity: 0.64,
};

export default theme;
