import styled from 'styled-components';

import { Col } from '../../components/UI/FlexboxGrid';

const Wrapper = styled.div`
`;

const Logo = styled.img`
    height: 40vmin;
    pointer-events: none;

    @keyframes App-logo-spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @media (prefers-reduced-motion: no-preference) {
        animation: App-logo-spin infinite 20s linear;
    }
`;

const Nav = styled.nav`
    // position: sticky;
    // top: 0;
    height: 100vh;
    overflow-y: scroll;
    ${({ theme }) => `
        padding: ${theme.spacing[3]} 0;
    `};
`;

const Link = styled.a`
    min-width: 140px;
    margin-bottom: 5px;
    display: block;
    padding: 5px 15px;
    // color: #616670;
    // color: #3aa7aa;
    color: #75c181;
    padding: .5rem 1rem;
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    background-color: transparent;
`;

const Content = styled.div`

`;

const Section = styled.section`
    height: calc(100vh - 65px);
    overflow-y: scroll;
    margin-top: 65px;
    ${({ theme }) => `
        background-color: ${theme.palette.grey[600]};

        > pre {
            margin: 100px ${theme.spacing[3]};
        }
    `};
`;

const NavigationWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 65px;
    ${({ theme }) => `
        padding: ${theme.spacing[2]} ${theme.spacing[3]};
        padding-left: ${theme.spacing[1]};
        background-color: ${theme.palette.grey[700]};
    `};
`;

const Header = styled.header`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    ${({ theme }) => `
        border-bottom: 1px solid ${theme.palette.borderDark};
        padding: ${theme.spacing[2]} ${theme.spacing[3]};
    `};
`;

const Title = styled.h2`
    display: ${({ mobile }) => mobile ? 'none' : 'block'};
    margin: 0;
    ${({ theme }) => theme.typography.h2};
    ${({ theme }) => `
        margin-bottom: ${theme.spacing[2]};
        color: ${theme.palette.white};
    `};

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        display: block;
    }
`;

const Subtitle = styled.h3`
    margin: 0;
    ${({ theme }) => theme.typography.h3};
    ${({ theme }) => `
        color: ${theme.palette.white};

        > a {
            color: ${theme.palette.white};
        }
    `};
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${({ theme }) => theme.typography.p};
    ${({ theme }) => `
        color: ${theme.palette.white};
        padding: ${theme.spacing[2]} ${theme.spacing[3]};
    `};

    > div {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .mjx-chtml {
        color: salmon;
    }
`;

const DescriptionPart = styled.div`
    ${({ theme }) => `
        margin-bottom: ${theme.spacing[2]};
    `};
}
`;

const Navigation = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const ColStyled = styled(Col)`
    position: relative;
`;

export { Wrapper, ColStyled, NavigationWrapper, Navigation, Logo, Nav, Link, Section, Header, Title, Subtitle, Description, DescriptionPart, Content };
