import React from 'react';
// import logo from './logo.svg';

// import MathJaxModule from 'mathjax';
import MathJax from 'react-mathjax';
import Latex from 'react-latex';

import { connect } from 'react-redux';
import asPage from '../../components/Page';

import { getProblems, getProblem } from '../../selectors';

import SyntaxHighlighter, { a11yDark as theme } from 'react-syntax-highlighter';

import FlexboxGrid, { Col } from '../../components/UI/FlexboxGrid';

import { Wrapper, ColStyled, NavigationWrapper, Navigation, Logo, Nav, Link, Section, Header, Title, Subtitle, Description, DescriptionPart } from './Home.style';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const Formula = ({ tex }) => (
    <MathJax.Provider script={false}>
        <MathJax.Node formula={tex} />
    </MathJax.Provider>
);

const Code = ({ code }) => (
    <SyntaxHighlighter language="python" style={theme} wrapLines>
        {code}
    </SyntaxHighlighter>
);

const Problem = ({ id, title, content, code }) => {
    let description = content;

    // extract TEX
    const texRegex = RegExp(/\$\$(.*?)\$\$/, 'gm');
    let match;

        const texParts = [];
        while ((match = texRegex.exec(content)) !== null) {
            texParts.push({
                from: match.index,
                to: texRegex.lastIndex,
                line: match[1],
            });
        }

        for (let a = texParts.length - 1; a >= 0; a -= 1) {
            const texPart = texParts[a];
            description = description.substring(0, texPart.from) + 'TEX' + a + 'TEX' + description.substring(texPart.to);
        }

    const parts = [];
    // const regex = RegExp(/<((p|div).*?)[^>.]*>(.*?)<\/((p|div).*?)>/, 'gm');
    const regex = RegExp(/(<((p|ol|div|blockquote).*?)>|TEX)(TEX)?(.*?)(TEX)?(<\/((p|ol|div|blockquote).*?)>|TEX)/, 'gm');

    const hasCode = Boolean(code);

    while ((match = regex.exec(description)) !== null) {
        // tex
        if (match[2] === undefined || match[4] === 'TEX') {
            parts.push({
                type: 'TEX',
                line: match[5],
            });
        }
        // tex
        else if (match[3] === undefined) {
            parts.push({
                type: 'TEX',
                line: match[4],
            });
        }
        // p or div
        else if (match[4] === undefined) {
            parts.push({
                type: match[2],
                line: match[5],
            });
        }
    }

    return (
        <Section>
            <Header>
                <Title>{title}</Title>
                <Subtitle><a target="_blank" href={`https://projecteuler.net/problem=${id}`}>Problem {id}</a></Subtitle>
            </Header>
            <Description>
                {parts.map((part, i) => {
                    const { type, line } = part;
                    if (type === 'TEX') {
                        const latex = texParts[parseInt(line, 0)].line.replace(/&amp;/g, '&');
                        return (
                            <DescriptionPart key={`part-${i}`}>
                                <MathJax.Provider>
                                    <MathJax.Node formula={latex} />
                                </MathJax.Provider>
                            </DescriptionPart>
                        )
                    }

                    const mathRegex = RegExp(/\$(.*?)\$/, 'gm');
                    let prevIndex = 0;
                    const allParts = [];
                    while ((match = mathRegex.exec(line)) !== null) {
                        if (match.index > prevIndex) {
                            allParts.push({
                                content: line.substring(prevIndex, match.index),
                                isMath: false,
                            });
                        }
                        allParts.push({
                            content: match[1],
                            isMath: true,
                        });
                        prevIndex = mathRegex.lastIndex;
                    }

                    if (!allParts) {
                        allParts.push({
                            content: line,
                            isMath: false,
                        });
                    }
                    else if (prevIndex < line.length) {
                        allParts.push({
                            content: line.substring(prevIndex),
                            isMath: false,
                        });
                    }

                    return (
                        <DescriptionPart key={`part-${i}`}>
                            <MathJax.Provider>
                                {allParts.map((partX, j) => {
                                    if (partX.isMath) {
                                        return <MathJax.Node inline={allParts.length > 1} formula={partX.content} />;
                                    }
                                    return <span key={`part-bit-${j}`} dangerouslySetInnerHTML={{__html: partX.content}} />
                                })}
                            </MathJax.Provider>
                        </DescriptionPart>
                    );
                })}
            </Description>
            {hasCode && <Code code={code} />}
        </Section>
    );
};

function Home({ problems = [], problem = {} }) {
    const { id, title } = problem;
    const total = problems.length;
    const problemId = parseInt(id, 10);
    const hasNext = problemId < total;
    const hasPrev = problemId > 1;
    return (
        <Wrapper>
            <FlexboxGrid
                justify={FlexboxGrid.Justify.CENTER}
                config={{
                    base: {
                        verticalSpacing: 0,
                        horizontalSpacing: 0,
                    },
                }}
            >
                <Col span={0} smallDesktop={3}>
                    <Nav>
                        {problems.map(problem => (
                            <Link
                                href={`/${problem.id}/`}
                                key={problem.id}
                            >
                                {`${problem.id} - ${problem.title}`}
                            </Link>
                        ))}
                    </Nav>
                </Col>
                <ColStyled span={12} smallDesktop={9}>
                    <NavigationWrapper>
                        <Navigation>
                            {hasPrev && <Link href={`/${problemId - 1}/`}>&larr; {`Problem ${problemId - 1}`}</Link> || <span />}
                            <Title mobile>{title}</Title>
                            {hasNext && <Link href={`/${problemId + 1}/`}>{`Problem ${problemId + 1}`} &rarr;</Link> || <span />}
                        </Navigation>
                    </NavigationWrapper>
                    <Problem {...problem} />
                </ColStyled>
            </FlexboxGrid>
        </Wrapper>
    );
};

const mapStateToProps = state => ({
    problems: getProblems(state),
    problem: getProblem(state),
});

export default asPage(connect(mapStateToProps, null)(Home), 'Home', true);
