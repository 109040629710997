import React, { useContext } from 'react';
import { FlexboxGridContext } from './FlexboxGrid';
import { ColStyled } from './FlexboxGrid.style';

type Props = {
    /**
     * The number of columns that this should span
     */
    span: number,
    /**
     * Allows this column to expand to fit the content
     * This will avoid the case where content overflows into neighbour
     * columns and becomes unreadable or unusable
     */
    fit: boolean,
};

const Col = ({ span, fit, children, ...props }: Props) => {
    const context = useContext(FlexboxGridContext);

    return (
        <ColStyled {...context} span={span} fit={fit} {...props}>
            {children}
        </ColStyled>
    );
};

Col.defaultProps = {
    span: 12,
    fit: false,
};

export default Col;
