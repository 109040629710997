// @flow
import styled from 'styled-components';

/**
 * Based on: https://codesandbox.io/s/q8j18o5jvw
 *
 * Because flexbox has no support for "gutters"/"gaps" we will approximate this by:
 * - Giving each flex item top/left margin of x (where x is the desired visual gap)
 * In order to set the number of columns we want (y) we will:
 * - Give each item a flex-basis which is like ((100% / 12) * y) - x
 * This will cause the correct columns and gaps to appear but it will also have margins at the edges
 * which we don't want so we will counter that with:
 * - Applying negative margins top & left (x) to the flex container
 *
 * We use top/left to avoid scrollbars appearing. In order to support RTL languages
 * we would need to flip this using something like: https://developer.mozilla.org/en-US/docs/Web/CSS/:dir
 *
 * The rest only looks complicated because of the media queries needed to support width and gutter
 * changes at different breakpoints. The core technique is as simple as the above explanation.
 */

const colWidth = (span, fit, config, totalCols) => {
    // The special case of `span={0}` should make it look like the column has been removed
    if (span === 0) return 'flex-basis: unset; max-width: unset;';

    const width = `calc(${(100 / totalCols) * span}% - ${config.horizontalSpacing}px)`;

    const flex = `flex-basis: ${width};`;
    const max = `max-width: ${width};`;

    // Always set flex-basis but only set max-width when we are forcing the width
    return fit ? flex : [flex, max].join('\n');
};

const colMargin = (span, config) =>
    (span === 0 ? '0' : `${config.verticalSpacing}px 0 0 ${config.horizontalSpacing}px`);

const colMedia = (breakpoint, span, fit, config, totalCols) =>
    `@media screen and (min-width: ${breakpoint}px) {
        ${colWidth(span, fit, config, totalCols)}
        margin: ${colMargin(span, config)};
    }`;

const gridNegativeMargin = config => `-${config.verticalSpacing}px 0 0 -${config.horizontalSpacing}px`;

const gridNegativeMarginMedia = (breakpoint, config) =>
    `@media screen and (min-width: ${breakpoint}px) {
        margin: ${gridNegativeMargin(config)};
    }`;

// A column needs:
// - A breakpoint each time the margins should change
// - A breakpoint each time the width should change
const generateColumnMediaQueries = (theme, defaultSpan, fit, config, props) => {
    const breakpointNames = Object.keys(theme.screenSize);
    const isBreakpoint = name => breakpointNames.indexOf(name) >= 0;
    const marginChanges = Object.keys(config).filter(isBreakpoint);
    const widthChanges = Object.keys(props).filter(isBreakpoint);
    const changes = [...marginChanges, ...widthChanges];

    // Figure out which grid config to use for each breakpoint
    // TODO: Could be done on theme init instead of dynamically?
    const configMap = {};
    let currentConfig = config.base;
    breakpointNames.forEach((breakpointName) => {
        configMap[breakpointName] = config[breakpointName] || currentConfig;

        currentConfig = configMap[breakpointName];
    });

    // Figure out which width to use for each breakpoint
    const spanMap = {};
    let currentSpan = defaultSpan;
    breakpointNames.forEach((breakpointName) => {
        const userSpan = props[breakpointName];

        spanMap[breakpointName] = userSpan !== undefined ? userSpan : currentSpan;

        currentSpan = spanMap[breakpointName];
    });

    const getTotalCols = (props, breakpoint) => {
        const breakpointProp = `${breakpoint}TotalCols`;
        if (breakpointProp in props) {
            return props[breakpointProp];
        }
        return props.totalCols;
    };

    // Now generate the minimum number of media queries required to represent all
    // of the width/gutter changes
    const mediaQueries = breakpointNames
        .filter(breakpointName => changes.indexOf(breakpointName) >= 0)
        .map(breakpointName =>
            colMedia(
                theme.screenSize[breakpointName],
                spanMap[breakpointName],
                fit,
                configMap[breakpointName],
                getTotalCols(props, breakpointName),
            ),
        );

    return mediaQueries.join('\n');
};

// Generate the MQs needed to change the negative margins
const generateGridNegativeMarginMediaQueries = (theme, config) => {
    const { base, ...marginChanges } = config;

    const mediaQueries = Object.keys(marginChanges).map(breakpointName =>
        gridNegativeMarginMedia(theme.screenSize[breakpointName], config[breakpointName]),
    );

    return mediaQueries.join('\n');
};

const ColStyled = styled.div`
    ${({ span, fit, totalCols, config }) => colWidth(span, fit, config.base, totalCols)}
    margin: ${({ span, config }) => colMargin(span, config.base)};

    // Add support for all of our breakpoints
    ${({ theme, span, fit, config, ...props }) => generateColumnMediaQueries(theme, span, fit, config, props)};
`;

const FlexboxGridStyled = styled.div`
    display: inline-block;
    width: 100%;

    ${({ debug }) => debug && 'position: relative;'};

    & ${ColStyled} {
        ${({ debug }) => debug && 'box-shadow: 0 0 0 1px magenta;'};
    }
`;

const FlexboxGridInnerStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: ${({ justify }) => justify};

    margin: ${({ config }) => gridNegativeMargin(config.base)};

    // Add support for all of our breakpoints
    ${({ theme, config }) => generateGridNegativeMarginMediaQueries(theme, config)};
`;

const DebugCol = styled(ColStyled).attrs({ span: 1 })`
    background: cyan;
    opacity: 0.2;
`;

const DebugFlexboxGridInnerStyled = styled(FlexboxGridInnerStyled)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    // Remove the shadow that was applied in FlexboxGridStyled from the debug
    // columns. Adding it here to increase specificity
    & ${DebugCol} {
        box-shadow: none;
    }
`;

export { FlexboxGridStyled, FlexboxGridInnerStyled, ColStyled, DebugCol, DebugFlexboxGridInnerStyled };
