import React, { Component, Fragment, PureComponent } from 'react';
import Helmet from 'react-helmet';
import GA from 'react-ga';

import ThemeProvider from '../../components/ThemeProvider';
import theme from '../../themes/default';
import global from '../../themes/global';

import posed, { PoseGroup } from 'react-pose';

import { renderChildRoutes } from '../../routes';

import { Main, Page } from './App.style';

const helmetConstants = require('react-helmet/lib/HelmetConstants.js');
helmetConstants.HELMET_ATTRIBUTE = 'data-trh';

const RouteContainer = posed.div({
    enter: { opacity: 1, y: 0, beforeChildren: true },
    exit: { opacity: 0, y: 0 },
});

class App extends PureComponent {
    componentDidMount() {
        this.props.history.listen((location, action) => {
            if (action === 'PUSH') {
                setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 0);
            }
        });

        GA.initialize('UA-56856033-1');
        GA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        // Inject global CSS
        global();

        const { location, route } = this.props;
        const { key } = location || {};

        return (
            <Fragment>
                <Helmet titleTemplate="Euler – Coder.si">
                    <title>Project Euler</title>
                    <meta property="og:url"                content="https://projecteuler.coder.si/" />
                    <meta property="og:type"               content="website" />

                    <meta name="generator" content="Coder Engine" />
                    <meta name="msapplication-TileColor" content="#ffffff" />
                    <meta name="theme-color" content="#ffffff" />
                </Helmet>

                <ThemeProvider theme={theme}>
                    <Main>
                        <PoseGroup>
                            <RouteContainer key={key || 'root'}>
                                {renderChildRoutes({ route })}
                            </RouteContainer>
                        </PoseGroup>
                    </Main>
                </ThemeProvider>
            </Fragment>
        );
    }
}

export default App;
