// @flow
import type { LocaleT } from '../types';

export const ActionTypes = {
    SETLOCALE: 'app/set-locale',
    LOAD_PROBLEMS: 'app/load-problems',
    LOAD_PROBLEM: 'app/load-problem',
    PROBLEMS_LOADED: 'app/problems-loaded',
    PROBLEM_LOADED: 'app/problem-loaded',
};

export const setLocale = (locale: LocaleT) => ({
    type: ActionTypes.SETLOCALE,
    data: locale,
});

export const loadProblems = () => ({
    type: ActionTypes.LOAD_PROBLEMS,
});

export const loadProblem = slug => ({
    type: ActionTypes.LOAD_PROBLEM,
    slug,
});
