import { put, takeEvery, all, take } from 'redux-saga/effects';

import * as pageActions from '../../components/Page/actions';
import { ActionTypes, loadProblems, loadProblem } from '../../actions';

export function* loadProblemsData({ params }) {
    const { slug = 1 } = params || {};
    yield put(loadProblems());
    yield put(loadProblem(slug));
}

function* load(action) {
    yield loadProblemsData(action);
    yield put(pageActions.loaded.create('Home'));
}

function* preload(action) {
    yield loadProblemsData(action);
    // Block until post is loaded
    yield take([ActionTypes.PROBLEMS_LOADED, ActionTypes.PROBLEM_LOADED]);
    yield put(pageActions.preloaded.create('Home'));
}

export default function* pageSaga() {
    yield all([
        takeEvery(action => action.type === pageActions.preload.ACTION && action.pageId === 'Home', preload),
        takeEvery(action => action.type === pageActions.load.ACTION && action.pageId === 'Home', load),
    ]);
}
