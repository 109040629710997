import { call, put, takeEvery, select } from 'redux-saga/effects';

import { ActionTypes } from '../actions';

import { reader } from './common';

const loadProblems = function* loadProblems(action) {
    const data = yield call(reader, {
        endpoint: `/api/problems/`,
    });

    yield put({
        type: ActionTypes.PROBLEMS_LOADED,
        data: data.response.data,
    });
};

const loadProblem = function* loadProblem(action) {
    const data = yield call(reader, {
        endpoint: `/api/problems/${action.slug}/`,
    });

    yield put({
        type: ActionTypes.PROBLEM_LOADED,
        data: data.response.data,
        slug: action.slug,
    });
};

export default function* () {
    yield takeEvery(ActionTypes.LOAD_PROBLEMS, loadProblems);
    yield takeEvery(ActionTypes.LOAD_PROBLEM, loadProblem);
}
