import { combineReducers } from 'redux';
import { routerReducer as router } from 'react-router-redux';

import app from '../reducers/app';
import problem from '../reducers/problem';
import problems from '../reducers/problems';

import page from '../components/Page/reducers';

const rootReducer = combineReducers({
    app,
    problem,
    problems,
    page,
    router,
});

export default rootReducer;
